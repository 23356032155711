<template>
  <div class="container test-results-new">
    <div class="clearfix">
      <div class="test-results__title">
        {{ $t('test-results.title') }}
      </div>
      <div class="test-results__tape">
        <div class="container h-100">
          <div class="test-results__tape-text">
            {{ $t('test-results.title-2') }}
          </div>
        </div>
      </div>
      <div v-if="dataEnd.substr(0, 10).split('-').reverse().join('.')" class="test-results__date-block">
        <div class="test-results__date">
          <img src="/images/test-date.svg" class="mr-3" width="24" height="24">
          {{ $t('test-results.test-date') }}
          {{ dataEnd.substr(0, 10).split('-').reverse().join('.') }}
        </div>
        <div class="test-results__date">
          <img src="/images/test-time.svg" class="mr-3" width="24" height="24">
          {{ $t('test-results.test-time') }}
          {{ timeTotal }}
        </div>
      </div>
      <div style="margin-top: 80px" class="test-results__sub-title">{{ $t('test-results.results') }}</div>
      <section class="test-results__section" v-for="(chart, index) in charts" :key="index" :id="'chart-' + index">
        <div class="test-results__sub-title">{{ chart.subject.name }}</div>
        <div v-for="(topic, key) in topics[index]" class="test-results__section-row mb-5">
          <div class="test-results__img">
            <img height="400px" :src="topic.topic.image">
          </div>
          <div class="test-results__card">
            <div class="test-results__card-title--recomend">
              {{ topic.topic.title }}
            </div>
            <div class="recomendation-wh">
              <div class="test-results__card-title--recomend-min">
                {{ topic.topic.recommendation.title }}
              </div>

              <div>{{ topic.topic.recommendation.description }}</div>
            </div>

<!--            <div v-if="topic.topic.recommendation.recommendation" class='recomendation-bg' style="border: 1px solid red">-->
<!--              <div class="test-results__card-title&#45;&#45;recomend-min">{{ $t('profile.children.recommendation') }}</div>-->
<!--              {{ topic.topic.recommendation.recommendation }}-->
<!--            </div>-->
          </div>
        </div>
      </section>
      <section style="margin-bottom: 80px" class="test-results__section" v-if="special_quiz_available">
        <div class="test-results__section-row">
          <div class="test-results__img">
            <img src="/images/2-step.svg"  width="400" height="400">
          </div>
          <div class="test-results__card" style="margin-left: auto">
            <div class="test-results__card-title test-results__card-title--recomend">
              {{ $t('main-page.test-results-special-test-title') }}
            </div>
            <div class="mt-4" >
              <div class="test-results__card-sub-title" >
              </div>
              <div class="text-results__card_text">
                <h5></h5>
                <p>{{ $t('main-page.test-results-special-test') }}</p>
                <router-link v-if="special_quiz_available"  style="text-decoration: none!important; color:white!important;    margin: auto;
    display: block;
    width: 56%;
    text-align: center;" to="/notes" class="test-results__consult-btn">
                  {{ $t('profile.notes.register-for-special-testing') }}
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="recommendation-title">{{ $t('profile.children.recommendation') }}</div>
        <div class="recomendation-wrapper">
          <div>
             <b>{{ $t('profile.results.results-recommendation-end-1') }}</b> <br>
            <p><b>{{ $t('profile.results.results-recommendation-end-2') }}</b></p> <br>
<!--            <span v-html="subjectRecommendation"></span>-->
            <div class="test-results__sub-title">
              Критическое мышление
            </div>
            <div class="test-results__section-row mb-5">
              <div class="test-results__img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/new-test-2.jpg"
                    fit="fit"></el-image>
              </div>
              <div class="test-results__card">
                <div class="test-results__card-title--recomend">
                  «Сложные объяснения»
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.complexExplanationsData && textRecommendations.complexExplanationsData.interpret">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-html="textRecommendations.complexExplanationsData.interpret">
                  </div>
                </div>

              </div>
            </div>


            <div class="test-results__sub-title">
              Креативность
            </div>
            <div class="test-results__section-row mb-5">
              <div class="test-results__img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/new-test-1.jpg"
                    fit="fit"></el-image>
              </div>
              <div class="test-results__card">
                <div class="test-results__card-title--recomend">
                  Методика «Креативный генератор»
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.creativitySubtest1Data && textRecommendations.creativitySubtest1Data.interpret">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 1. Составление изображения
                  </div>
                  <div v-html="textRecommendations.creativitySubtest1Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.creativitySubtest2Data && textRecommendations.creativitySubtest2Data.interpret">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 2. Составление слов
                  </div>
                  <div v-html="textRecommendations.creativitySubtest2Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.creativitySubtest3Data && textRecommendations.creativitySubtest3Data.interpret">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 3. Составление изображений
                  </div>
                  <div v-html="textRecommendations.creativitySubtest3Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.hiddenFormData && textRecommendations.hiddenFormData.interpret">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 4. Спрятанная форма
                  </div>
                  <div v-html="textRecommendations.hiddenFormData.interpret">
                  </div>
                </div>

              </div>
            </div>


            <div class="test-results__sub-title">
              Коммуникация
            </div>
            <div class="test-results__section-row mb-5">
              <div class="test-results__img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/new-test-1.jpg"
                    fit="fit"></el-image>
              </div>
              <div class="test-results__card">
                <div class="test-results__card-title--recomend">
                  Методика «Один за всех и все за одного!»
                </div>
                <div class="recomendation-wh" v-if="textRecommendations.funnyStoriesData && textRecommendations.funnyStoriesData.interpret">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-html="textRecommendations.funnyStoriesData.interpret">
                  </div>
                </div>

              </div>
            </div>

            <div class="test-results__sub-title">
              Коллаборация
            </div>
            <div class="test-results__section-row mb-5">
              <div class="test-results__img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/new-test-2.jpg"
                    fit="fit"></el-image>
              </div>
              <div class="test-results__card">
                <div class="test-results__card-title--recomend">
                  Методика: Личностный опросник К.Томаса
                </div>
                <div class="recomendation-wh">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-if="textRecommendations.thomasQuestionnaireData && textRecommendations.thomasQuestionnaireData.score">
                    <p>соперничество - {{textRecommendations.thomasQuestionnaireData.score.rivalry}}</p>
                    <p>сотрудничество - {{textRecommendations.thomasQuestionnaireData.score.cooperation}}</p>
                    <p>компромисс - {{textRecommendations.thomasQuestionnaireData.score.compromise}}</p>
                    <p>избежание - {{textRecommendations.thomasQuestionnaireData.score.avoid}}</p>
                    <p>приспособление - {{textRecommendations.thomasQuestionnaireData.score.adaptation}}</p>
                  </div>
                </div>

              </div>
            </div>
            <b>{{ $t('profile.results.results-recommendation-end-4') }}</b> <br>
          </div>
        </div>
      </section>

      <ul class="sponsor-list mt-5">
        <li><a href="https://fnn.kz" target="_blank" class="sponsor-fond-link"><i
            v-bind:class="{'sponsor-fond': this.$i18n.locale === 'ru', 'sponsor-fond-kz': this.$i18n.locale === 'kz'}"></i></a>
        </li>
        <li><a href="#" target="_blank" class="sponsor-fond-elumiti"><img src="/images/EA_logo_final.svg" width="46" alt=""
                                                                          class="img-fluid"></a></li>
        <li class="sponsor-fond-circle-logo"><img src="/images/MO-bg.png" width="46" alt="" class="img-fluid"></li>
      </ul>
      <div class="test-results__btns mt-5">
        <router-link to="/testing" style="text-decoration: none!important; color:white!important"
                     class="test-results__other-btn">
          {{ $t('test-results.other-tests') }}
        </router-link>
        <router-link style="text-decoration: none!important; color:white!important" to="/notes"
                     class="test-results__consult-btn">
          {{ $t('test-results.consultations-appointment') }}
        </router-link>

        <div class="test-results__download-block">
          <button @click="generateReport()" class="test-results__download-btn">
            <img src="/images/download-icon.svg" width="16" height="16" class="mr-2">
            {{ $t('test-results.download-file') }}
          </button>
          <div class="test-results__download-title">
            {{ $t('test-results.download-file-name') + ' ' + fio + '.pdf' }}
          </div>
        </div>

      </div>
    </div>
    <vue-html2pdf
        v-show="false"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :enableLinks="true"
        :paginate-elements-by-height="1600"
        :filename="$t('test-results.download-file-name') + ' ' + fio"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        :html-to-pdf-options="htmlToPdfOptions"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
      <div slot="pdf-content" class="container" style="box-shadow: none!important">
        <div class="clearfix">
          <div class="test-results__title pdf-item">
            {{ $t('test-results.title') }}
          </div>
          <div class="test-results__tape pdf-item">
            <div class="container h-100">
              <div class="test-results__tape-text">
                {{ $t('test-results.title-2') }}
              </div>
            </div>
          </div>
          <div style="margin-top: 80px;" class="test-results__sub-title pdf-item">{{ $t('test-results.results') }}</div>

          <section class="test-results__section pdf-item" style="padding: 0 !important;" v-if="textRecommendations.complexExplanationsData && textRecommendations.complexExplanationsData.interpret">
            <div class="html2pdf__page-break"/>
            <div class="test-results__sub-title pdf-item" style="padding-bottom: 10px;">Критическое мышление</div>
            <div class="test-results__section-row pdf-item" style="align-items: flex-start !important; margin-bottom: 80px !important; padding: 0 !important;">
              <div class="test-results__img  pdf-item">
                <img height="200px" src="/images/new-test-2-1.jpg">
              </div>
              <div class="test-results__card pdf-item" style="box-shadow: none!important;">
                <div class="test-results__card-title--recomend">
                  «Сложные объяснения»
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-html="textRecommendations.complexExplanationsData.interpret">
                  </div>
<!--                  <div v-if="this.$i18n.locale === 'ru'" v-html="textRecommendations.collaborationSendAnswers[44].orientationToProfessions.ru">-->
<!--                  </div>-->
<!--                  <div v-if="this.$i18n.locale === 'kz'" v-html="textRecommendations.collaborationSendAnswers[44].orientationToProfessions.kz">-->
<!--                  </div>-->
<!--                  <div v-if="this.$i18n.locale === 'ru'" v-html="textRecommendations.collaborationSendAnswers[45].motivationCommunicate.ru">-->
<!--                  </div>-->
<!--                  <div v-if="this.$i18n.locale === 'kz'" v-html="textRecommendations.collaborationSendAnswers[45].motivationCommunicate.kz">-->
<!--                  </div>-->
                </div>

              </div>
              <div class="html2pdf__page-break"/>
            </div>
          </section>

          <section class="test-results__section pdf-item" style="padding: 0 !important;break-before: page;" v-if="textRecommendations.creativitySubtest1Data && textRecommendations.creativitySubtest1Data.interpret">
            <div class="html2pdf__page-break"/>
            <div class="test-results__sub-title pdf-item" style="padding-bottom: 10px;">Креативность</div>
            <div class="test-results__section-row pdf-item" style="align-items: flex-start !important; margin-bottom: 80px !important; padding: 0 !important;">
              <div class="test-results__img  pdf-item">
                <img height="200px" src="/images/new-test-1-1.jpg">
              </div>
              <div class="test-results__card pdf-item" style="box-shadow: none!important;">
                <div class="test-results__card-title--recomend">
                  Методика «Креативный генератор»
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 1. Составление изображения
                  </div>
                  <div v-html="textRecommendations.creativitySubtest1Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 2. Составление слов
                  </div>
                  <div v-html="textRecommendations.creativitySubtest2Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 3. Составление изображений
                  </div>
                  <div v-html="textRecommendations.creativitySubtest3Data.interpret">
                  </div>
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">
                    Субтест 4. Спрятанная форма
                  </div>
                  <div v-html="textRecommendations.hiddenFormData.interpret">
                  </div>
                </div>

              </div>
              <div class="html2pdf__page-break"/>
            </div>
          </section>

          <section class="test-results__section pdf-item" style="padding: 0 !important;" v-if="textRecommendations.funnyStoriesData && textRecommendations.funnyStoriesData.interpret">
            <div class="html2pdf__page-break"/>
            <div class="test-results__sub-title pdf-item" style="padding-bottom: 10px;">Коммуникация</div>
            <div class="test-results__section-row pdf-item" style="align-items: flex-start !important; margin-bottom: 80px !important; padding: 0 !important;">
              <div class="test-results__img  pdf-item">
                <img height="200px" src="/images/new-test-1-1.jpg">
              </div>
              <div class="test-results__card pdf-item" style="box-shadow: none!important;">
                <div class="test-results__card-title--recomend">
                  Методика «Один за всех и все за одного!»
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-html="textRecommendations.funnyStoriesData.interpret">
                  </div>
<!--                  <div v-if="this.$i18n.locale === 'ru'" v-html="textRecommendations.creativitySendAnswers[3].textsInterpretation.ru">-->
<!--                  </div>-->
<!--                  <div v-if="this.$i18n.locale === 'kz'" v-html="textRecommendations.creativitySendAnswers[3].textsInterpretation.kz">-->
<!--                  </div>-->
                </div>

              </div>
              <div class="html2pdf__page-break"/>
            </div>
          </section>

          <section class="test-results__section pdf-item" style="padding: 0 !important;">
            <div class="html2pdf__page-break"/>
            <div class="test-results__sub-title pdf-item" style="padding-bottom: 10px;">Коллаборация</div>
            <div class="test-results__section-row pdf-item" style="align-items: flex-start !important; margin-bottom: 80px !important; padding: 0 !important;">
              <div class="test-results__img  pdf-item">
                <img height="200px" src="/images/new-test-2-1.jpg">
              </div>
              <div class="test-results__card pdf-item" style="box-shadow: none!important;">
                <div class="test-results__card-title--recomend">
                  Методика: Личностный опросник К.Томаса
                </div>
                <div class="recomendation-wh pdf-item">
                  <div class="test-results__card-title--recomend-min">

                  </div>
                  <div v-if="textRecommendations.thomasQuestionnaireData && textRecommendations.thomasQuestionnaireData.score">
                    <p>соперничество - {{textRecommendations.thomasQuestionnaireData.score.rivalry}}</p>
                    <p>сотрудничество - {{textRecommendations.thomasQuestionnaireData.score.cooperation}}</p>
                    <p>компромисс - {{textRecommendations.thomasQuestionnaireData.score.compromise}}</p>
                    <p>избежание - {{textRecommendations.thomasQuestionnaireData.score.avoid}}</p>
                    <p>приспособление - {{textRecommendations.thomasQuestionnaireData.score.adaptation}}</p>
                  </div>
<!--                  <div v-if="this.$i18n.locale === 'ru'" v-html="textRecommendations.communicationsSendAnswers[3].textsInterpretation.ru">-->
<!--                  </div>-->
<!--                  <div v-if="this.$i18n.locale === 'kz'" v-html="textRecommendations.communicationsSendAnswers[3].textsInterpretation.kz">-->
<!--                  </div>-->
                </div>

              </div>
              <div class="html2pdf__page-break"/>
            </div>
          </section>

          <div class="html2pdf__page-break"/>
          <section class="pdf-item" style="margin-top: -98px">
            <div class="recommendation-title">{{ $t('profile.children.recommendation') }}</div>
            <div class="recomendation-wrapper" style="box-shadow: none!important">
              <div>
                <b>{{ $t('profile.results.results-recommendation-end-1') }}</b> <br>
                <p><b>{{ $t('profile.results.results-recommendation-end-2') }}</b></p> <br>
                <span v-html="subjectRecommendation"></span>
                <b>{{ $t('profile.results.results-recommendation-end-3') }}</b> <br>
              </div>
            </div>
          </section>

          <ul class="sponsor-list mt-5" style="align-items: flex-start !important;">
            <li><a href="https://fnn.kz" target="_blank" class="sponsor-fond-link"><i
                v-bind:class="{'sponsor-fond': this.$i18n.locale === 'ru', 'sponsor-fond-kz': this.$i18n.locale === 'kz'}"></i></a>
            </li>
            <li class="link-img-elumiti"></li>
            <li class="link-img-fond"></li>
          </ul>

        </div>
      </div>
    </vue-html2pdf>
  </div>
</template>
<script>
// import PolarAreaChart from '@/components/PolarAreaChart'
import VueApexCharts from "vue-apexcharts";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueApexCharts,
    VueHtml2pdf
  },
  data() {
    return {
      textRecommendations: '',
      htmlToPdfOptions: {
        pagebreak: { mode: ['css',] },
        filename: ``,
        margin: [0.4, 0.1, 0.48, 0.1],
        image: {
          type: 'image/jpeg',
          quality: 0.6
        },
        enableLinks: false,
        html2canvas:{
          letterRendering: true,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
          compress: true,
        }
      },
      fio:'',
      subjectRecommendation:'',
      special_quiz_available: false,
      user: '',
      id: undefined,
      results: {},
      passedDate: new Date(),
      charts: {},
      chartSeries: [],
      chartOptions: {},
      subjects: [],
      topics: [],
      radialSeries: [],
      radialOptions: {},
      timeTotal: '',
      dataEnd: '',
      apiRoot: window.API_ROOT,
    }
  },
  methods: {
    generateReport() {
      this.htmlToPdfOptions.filename=this.$t('test-results.download-file-name') + ' ' + this.fio
      this.$refs.html2Pdf.generatePdf()
    },
    dataPointSelectionHandler(event, chartContext, config) {
      if (event.target.classList[1] != null && event.target.classList[1] != 'apexcharts-align-center') {
        let selectedBlock = event.target.classList[1].slice(-1);
        let allTopics = document.querySelectorAll(`#chart-${chartContext.w.config.chart.id} .topic-block`);
        let selectedTopic = document.querySelector(`#chart-${chartContext.w.config.chart.id} #topic-${selectedBlock}`);
        allTopics.forEach((topic, key) => {
          if (topic != null) {
            topic.parentElement.classList.add('d-none');
          }
        });
        if (selectedTopic != null) {
          selectedTopic.parentElement.classList.remove('d-none');
        }
      }
    },
    formatDate(date, format) {
      let month = date.getMonth() + 1;
      const map = {
        mm: (month < 10) ? '0' + month : month,
        dd: date.getDate(),
        yy: date.getFullYear(),
        yyyy: date.getFullYear()
      }
      return format.replace(/mm|dd|yy|yyy/gi, matched => map[matched])
    },
    getResultNew: async function () {
      this.id = this.$route.query.id
      const userQuizId = this.$route.params.id
      const token = this.$route.params.token

      this.$http.get(window.QUIZ_API_ROOT + `/api/selected/${userQuizId}/${token}`)
          .then((res) => {
            this.textRecommendations = res.data.data[""].answer_words
          })
    }
  },
  mounted() {
    this.getResultNew()
  }
}
</script>
<style>

.link-img-elumiti {
  width: 125px;
  height: 125px;
  background-image: url("/images/EA_logo_final.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.link-img-fond {
  width: 100px;
  height: 100px;
  background-image: url("/images/partner-1-1.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.recomendation-wrapper {
  margin-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  border-radius: 15px;
  padding: 30px 27px;
  font-family: "Roboto Condensed", sans-serif;
}

.recommendation-title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.apexcharts-legend {
  padding-top: 21px;
  margin-top: 1rem !important;
  inset: auto 0px -2px !important;
  position: absolute;
  max-height: 166.667px;
}

.sponsor-list .sponsor-fond-link .sponsor-fond {
  width: 120px;
  height: 87px;
  background-size: contain;
  display: block;
  background-repeat: no-repeat;
}

.sponsor-fond-circle-logo img {
  width: 100px;
  height: 100px;
}

.sponsor-fond-elumiti img {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 0;
}

.sponsor-list li {
  margin-right: 35px;
}

.nav-scroller {
  display: none !important;
}

.test-results__title {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #343F68;
  padding-top: 60px;
  padding-bottom: 40px;
}

.test-results__tape {
  width: calc(100% - 50px);
  height: 65px;
  border-right: 33px solid transparent;
  border-top: 33px solid #1B83B1;
  border-left: 33px solid #1B83B1;
  border-bottom: 33px solid #1B83B1;
  position: absolute;
  left: 0;
}

.test-results__tape-text {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  padding-left: 24px;
}

.test-results__date-block {
  margin-top: 113px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-results__date {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 14px 16px;
  align-items: center;
  font-size: 20px;
  line-height: 23px;
  color: #303030;
}

.test-results__date-block .test-results__date:first-child {
  margin-right: 20px;
}

.test-results__sub-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  padding-bottom: 40px;
}

.test-results__section {
  /*margin-top: 80px;*/
}

.test-results__section-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.test-results__card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 30px 27px;
  width: 700px !important;
  height: fit-content;
  font-family: "Roboto Condensed", sans-serif;
  margin-left: 20px;
}

.test-results__card-title {
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  color: #1B83B1;
  text-align: center;
  padding-bottom: 16px;
}

.test-results__card-title--yellow {
  color: #F3A93C;
}

.test-results__card-title--recomend {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #1B83B1;
}

.recomendation-bg {

  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: #e0f4fd;
}

.recomendation-wh {
  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: white;
}

.test-results__card-title--recomend-min {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #1B83B1;
}

.test-results__card-sub-title {
  color: #F3A93C;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 28px;
  text-align: center;
}

.test-results__card-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}

.test-results__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 84px;
  align-items: center;
}

.test-results__other-btn,
.test-results__consult-btn {
  border: none;
  border-radius: 5px;
  padding: 10px 24px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  height: fit-content;
  outline: none;
}

.test-results__other-btn {
  background: #1B83B1;
}

.test-results__consult-btn {
  background: #18A0FB;
  margin-left: 24px;
  margin-right: 24px;
}

.test-results__download-block {
  display: flex;
  flex-direction: row;
  padding: 4px;
  border-radius: 4px;
  border: 1px dashed #1B83B1;
  align-items: center;
}

.test-results__download-btn {
  color: #0070D2;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  padding: 5px 23px;
  outline: none;
}

.test-results__download-title {
  color: #737373;
  font-size: 13px;
  line-height: 20px;
  margin-left: 22px;
  margin-right: 12px;
}

.test-results__img {
  width: 425px;
  display: flex;
  justify-content: center;
}

.apexcharts-radialbar .apexcharts-datalabels-group {
  display: none;
}

@media screen and (max-width: 1200px) {
  .test-results__card {
    width: 453px;
  }
}

@media screen and (max-width: 991px) {
  .test-results__tape-text {
    padding-left: 0;
  }

  .test-results__section-row {
    flex-direction: column;
  }

  .test-results__card {
    margin-left: 0;
    margin-top: 24px;
  }

  .test-results__date-block {
    flex-direction: column;
    align-items: center;
  }

  .test-results__date-block .test-results__date:first-child {
    margin-right: 0;
    margin-bottom: 12px;
    width: fit-content;
  }

  .test-results__btns {
    flex-direction: column;
    align-items: center;
  }

  .test-results__consult-btn {
    margin: 20px 0;
  }

  .test-results__card {
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .test-results__tape-text {
    font-size: 16px;
  }

  .test-results__img {
    width: 100%;
  }

  .test-results__img-recomend {
    width: 312px;
    height: 217px;
  }

  .test-results__img-result {
    width: 312px;
    height: 312px;
  }

  .test-results-new .test-results__card {
    max-width: 100%;
    padding-top: 0;
  }

  .test-results-new .recomendation-wh  {
    padding: 0;
  }
}
@media screen and (max-width: 459px) {
  .sponsor-list{
    padding-left: 0!important;
    flex-direction: column;
    align-items: none;

  }
  .sponsor-list li {
    margin: 1rem auto 0;
  }
  .test-results__btns {

    padding-bottom: 160px;

  }
}

</style>
